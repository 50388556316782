<template>
	<div class="component-submenu">
		<div class="container-menu-topo">
			<div class="container-botoes-navegacao">
				<button class="botao-menu flex lg1220:hidden" @click="botaoMobileMenuClick">
					<svg class="icon-hamburguer" style="color: #001e50" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
						<path d="M0 4h24v2H0zM0 11h14v2H0zM0 18h20v2H0z"></path>
					</svg>
				</button>

				<div class="hidden lg1220:block" v-for="(item, index) in submenus" :key="index">
					<NuxtLink v-if="!item.isExternalUrl" class="botao-menu" :to="item.url" :key="index" gtm-action="click" @click.native="triggerMenuClick(item.url)" :gtm-value="`menu-topo:${item.gtmValue}`" :target="verificarUrlExterna(item) ? '_blank' : false">
						<div @mouseenter="item.dropdownFile ? dropdownMouseEnter($event, item.id) : false" @mouseleave="item.dropdownFile ? dropdownMouseLeave($event, item.id) : false">
							<span class="container-icone-label">
								<i v-if="item.classIcon === 'produtos'" class="icon-produtos"></i>
								<i v-if="item.classIcon === 'institucional'" class="icon-institucional"></i>
								<i v-if="item.classIcon === 'servicos'" class="icon-servicos"></i>
								<i v-if="item.classIcon === 'representantes'" class="icon-representantes"></i>
								<i v-if="item.classIcon === 'assistencia'" class="icon-assistencia"></i>
								<i v-if="item.classIcon === 'contato'" class="icon-contato"></i>
								<span class="label-submenu">{{ item.label }}</span>
								<i v-if="item.icon" style="line-height: 2" class="fas fa-chevron-down"></i>
							</span>
						</div>
					</NuxtLink>
					<a class="font-16 label-submenu" v-else target="_blank" :href="item.url">{{ item.label }}</a>
				</div>
			</div>
		</div>

		<div
			class="container-menu-dropdown"
			:key="'submenu-item-' + submenuIndex"
			@mouseenter="dropdownMouseEnter($event, submenuItem.id)"
			@mouseleave="dropdownMouseLeave($event, submenuItem.id)"
			v-show="submenus[submenuItem.id].ativo"
			v-for="(submenuItem, submenuIndex) in submenus"
		>
			<div class="container-flutuante">
				<div class="col-categoria" :key="'categoria-' + categoriaIndex" v-for="(categoria, categoriaIndex) in submenuItem.dadosDropdown">
					<div class="container-titulo">
						<NuxtLink v-if="categoria.url !== 'empty'" class="head-bold font-16" style="color: #000" gtm-action="click" :gtm-value="`menu-topo:${submenuItem.gtmValue}:${$helpers.criarUrlAmigavel(categoria.label)}`" :to="'/' + categoria.url">{{ categoria.label }}</NuxtLink>

						<div style="color: #000" class="head-bold font-16" v-else>{{ categoria.label }}</div>
					</div>
					<div class="container-links">
						<ul>
							<li :key="'link-' + index" v-for="(link, index) in categoria.children">
								<NuxtLink class="title-light font-16" gtm-action="click" :gtm-value="`menu-topo:${submenuItem.gtmValue}:${$helpers.criarUrlAmigavel(categoria.label)}:${$helpers.criarUrlAmigavel(link.label)}`" :to="'/' + link.url"> {{ link.label }}</NuxtLink>
							</li>

							<li>
								<NuxtLink class="link font-16" v-if="categoria.url !== 'empty'" gtm-action="click" :gtm-value="`menu-topo:${submenuItem.gtmValue}:${$helpers.criarUrlAmigavel(categoria.label)}:ver-mais`" :to="'/' + categoria.url"> Ver mais</NuxtLink>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="container-links-mobile" v-if="containerLinksMobile.ativo">
			<NuxtLink
				class="botao-menu flex"
				:to="item.url"
				:key="'botao-menu-' + index"
				gtm-action="click"
				:gtm-value="`menu-topo:${item.gtmValue}`"
				:target="verificarUrlExterna(item) ? '_blank' : false"
				v-for="(item, index) in submenus"
				@click.native="containerLinksMobile.ativo = false"
			>
				<div @mouseenter="item.dropdownFile ? dropdownMouseEnter($event, item.id) : false" @mouseleave="item.dropdownFile ? dropdownMouseLeave($event, item.id) : false">
					<span class="container-icone-label">
						<img v-if="item.classIcon === 'pecas'" src="/custom/loja/assets/media/icons/peca.svg" alt="Peças" />
						<img v-if="item.classIcon === 'acessorios'" src="/custom/loja/assets/media/icons/acessorios.svg" alt="Acessórios" />
						<img v-if="item.classIcon === 'collection'" src="/custom/loja/assets/media/icons/collection.svg" alt="Collection" />
						<img v-if="item.classIcon === 'veiculos'" src="/custom/loja/assets/media/icons/veiculos.svg" alt="Veículos" />
						<img v-if="item.classIcon === 'lojas'" src="/custom/loja/assets/media/icons/lojas.svg" alt="Lojas" />
						<span class="font-16">{{ item.label }}</span>
					</span>
				</div>
			</NuxtLink>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"

export default {
	name: "ComponentSubmenu",
	props: {
		config: {
			type: Object,
		},
	},
	data() {
		return {
			containerLinksMobile: {
				ativo: false,
			},

			submenus: [],
		}
	},
	computed: {
		...mapGetters(["configJson", "featuresJson"]),

		baseUrl() {
			return process.env.baseUrl
		},

		route() {
			return this.$route
		},

		quantidadeProdutosAdicionados() {
			return this.$store.getters["Carrinho/quantidadeProdutosAdicionados"]
		},
	},
	methods: {
		triggerMenuClick(url) {
			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("link-menu", {
					url: url,
				})
			}
		},
		esconderSubmenus() {
			const submenus = this.submenus
			const submenusKeys = Object.keys(submenus)

			submenusKeys.forEach((item, index) => {
				this.$set(this.submenus[item], "ativo", false)
			})
		},

		montarMenus() {
			const submenuConfig = this.config.submenuConfig
			let submenuObj = {}

			if (submenuConfig) {
				submenuConfig.map(async (item, index) => {
					const id = this.$helpers.uuidv4()
					const gtmValue = this.$helpers.criarUrlAmigavel(item.label)

					let dadosDropdown = {}

					this.$set(submenuObj, id, {
						...item,
						ativo: false,
						dadosDropdown: {},
						gtmValue,
						id,
						mouseenter: false,
						timeout: {},
					})

					if (submenuObj[id].dropdownFile) {
						axios.get(`${process.env.baseUrl}${submenuObj[id].dropdownFile}`).then((response) => {
							dadosDropdown = response.data

							submenuObj[id].dadosDropdown = dadosDropdown
						})
					}
				})

				this.$set(this, "submenus", submenuObj)
			}
		},

		verificarUrlExterna(item) {
			const url = item.url

			return url.match(/^(?:http[s]?):/) !== null
		},

		//Events
		botaoMobileMenuClick(e) {
			this.containerLinksMobile.ativo = !this.containerLinksMobile.ativo
		},

		dropdownMouseEnter(e, id) {
			const submenuAtual = this.submenus[id]

			if (this.$mq.match(/lg/) && submenuAtual) {
				clearTimeout(submenuAtual.timeout)

				this.$set(this.submenus[id], `mouseenter`, true)

				const st = setTimeout(() => {
					if (submenuAtual.mouseenter) {
						clearTimeout(submenuAtual.timeout)

						this.esconderSubmenus()

						this.$set(this.submenus[id], "ativo", true)
					}
				}, 500)

				this.$set(this.submenus[id], "timeout", st)
			}
		},

		dropdownMouseLeave(e, id) {
			const submenuAtual = this.submenus[id]

			if (submenuAtual) {
				this.submenus[id].mouseenter = false

				this.submenus[id].timeout = setTimeout(() => {
					this.submenus[id].ativo = false
				}, 500)
			}
		},
	}, //methods
	watch: {
		route(value, oldValue) {
			this.esconderSubmenus()
		},
	},
	beforeMount() {
		this.montarMenus()
	},
}
</script>

<style lang="scss" scoped>
.container-icone-label {
	display: flex;
	gap: 8px;
	@media (max-width: 768px) {
		align-items: center;
	}
	i {
		color: var(--label-menu-text-color);
	}
}
.component-submenu {
	position: relative;
	width: 100%;
}

.component-submenu .container {
	display: flex;
	align-items: center;
}

.container-menu-topo {
	background-color: #000;
	border-radius: 1px;
	position: relative;
}

.container-botoes-navegacao {
	flex: 1;
	display: flex;
}

.container-botoes-navegacao .botao-menu {
	align-items: center;
	cursor: pointer;
	line-height: 100%;
}

.container-botoes-navegacao .botao-menu {
	text-decoration: none;
}

.container-botoes-navegacao .botao-menu .container-icone-label {
	display: inline-flex;
	align-items: center;
}

.container-menu-dropdown {
	position: relative;
	z-index: 1000000;
}

.container-menu-dropdown .container-flutuante {
	display: flex;
	background-color: #fff;
	border-top: none;
	box-shadow: 0 12px 6px 6px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	padding: 15px 15px 30px;
	position: fixed;
	max-width: 1220px;
	width: 100%;
	z-index: 100;
	top: 107px;
}

.container-menu-dropdown .col-categoria {
	flex: 1;

	margin-right: 1em;
}

.container-menu-dropdown .col-categoria:nth-child(5) {
	margin-right: 0;
}
.container-menu-dropdown .col-categoria:nth-child(n + 6) {
	display: none;
}

.container-menu-dropdown ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.container-menu-dropdown ul li {
	margin-bottom: 0.65em;
	padding-right: 0.65em;
}

.container-menu-dropdown ul li:last-child i {
	font-size: 0.85em;
}

.container-menu-dropdown .container-titulo {
	line-height: 100%;
	margin-bottom: 0.65em;
}

.container-menu-dropdown .container-links {
	line-height: 85%;
}

.container-menu-dropdown .container-links a {
	color: #111;
	display: inline-block;
	/* font-size: 0.75em; */
}

@keyframes fadeValorQuantidadeItens {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0;
		transform: scale(1.2);
	}
	51% {
		opacity: 0;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@media (max-width: 767px) {
	.container-botoes-navegacao {
		border: none;
		height: auto;
	}

	.container-botoes-navegacao .botao-menu {
		border: none;
		margin-right: 15px;
		padding: 0;
	}

	.container-botoes-navegacao .botao-menu:last-child {
		border: none;
	}
}

@media (max-width: 1199px) {
	.container-botoes-navegacao .botao-menu .container-icone-label > span {
		font-size: 1em;
	}

	.container-links-mobile {
		margin-top: 16px;
		background-color: #123A64 !important;
	}

	.container-links-mobile .botao-menu {
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		color: var(--label-menu-text-color);
		height: 54px;
		line-height: 100%;
		padding: 1em;
		font-family: var(--head-bold);
	}

	.container-links-mobile .botao-menu:first-child {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}
}
.container-links-mobile {
	position: absolute;
	background: var(--header-bg-color);
	min-width: 100vw;
	top: 72px;
	left: -16px;
}
.container-menu-topo {
	height: 100%;
	display: flex;
	align-items: center;
}
.container-botoes-navegacao {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 28px;
}
.botao-menu {
	height: 100%;
	align-items: center;
	gap: 4px;
	position: relative;
	@media (min-width: 768px) {
		&:after {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 1px;
			background: var(--hover-menu-color);
			content: "";
			opacity: 0;
			transition: height 0.3s, opacity 0.3s, transform 0.3s;
			transform: translateY(-10px);
		}
		&:hover {
			&:after {
				height: 2px;
				opacity: 1;
				-webkit-transform: translateY(0px);
				-moz-transform: translateY(0px);
				transform: translateY(0px);
			}
		}
	}
}
.label-submenu {
	color: var(--label-menu-text-color);
	font-family: var(--head-bold);
	transition: all 0.3s ease-in-out;
	font-size: 14px;
}
.icon-hamburguer {
	path {
		color: var(--icon-hamburguer-color);
	}
}
.container-icone-label {
	display: flex;
	flex-direction: column;
	gap: 10px;
	i {
		transition: all 0.3s ease-in-out;
	}
	&:hover {
		.label-submenu,
		i {
			color: #007acc;
		}
	}
}
</style>
