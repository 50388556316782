import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a4bb18ec = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _13501b26 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _61bc263e = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _7759abf2 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _0c6393a0 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _61be7aae = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _a87367f8 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _4878b8ec = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _8d0f737c = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _ed30e18e = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _3f1159ca = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _46a58186 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _2f12d8df = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _61994a59 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _5adbcbb9 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _04c6dfc2 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _3df1b6e1 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _88e9cd38 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _c6100e40 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/lojas",
    component: _a4bb18ec,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _13501b26,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _61bc263e,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _7759abf2,
    name: "motos"
  }, {
    path: "/reset",
    component: _0c6393a0,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _61be7aae,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _a87367f8,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _4878b8ec,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _8d0f737c,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _ed30e18e,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _3f1159ca,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _46a58186,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _2f12d8df,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _61994a59,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _5adbcbb9,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _04c6dfc2,
    name: "produto-id-all"
  }, {
    path: "/lista/*",
    component: _3df1b6e1,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _88e9cd38,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _c6100e40,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
