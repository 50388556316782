const axios = require("axios")
const lodash = require("lodash")

let API_B2C_BACKEND = process.env.API_B2C_BACKEND
let BASE_URL = process.env.BASE_URL
let TOKEN_EPA = process.env.TOKEN_EPA
let ENV_NAME = process.env.ENV_NAME

export const state = () => ({
	app: {},
	autenticado: false,
	cep: null,
	cepBloqueado: null,
	cidadeSelecionada: null,
	concessionariaSelecionada: {},
	configJson: null,
	bannerJson: null,
	enderecos: [],
	featuresJson: null,
	localesJson: null,
	marketingJson: null,
	paginasEstaticas: {
		lista: [],
	},
	usuario: {},
	veiculoSelecionado: {},
	availableStores: [],
	lastSearch: "",
	marketingInfo: [],
	carouselFeaturedIds: [],
	carouselCategories: [],
})

export const getters = {
	featuredIds: (state) => {
		let productIds = []
		let carouselFeaturedIds = state.carouselFeaturedIds.split(",")
		carouselFeaturedIds.forEach(function (id) {
			productIds.push(parseInt(id))
		})
		return productIds
	},
	carouselCategory: (state) => {
		return state.carouselCategories[Math.floor(Math.random() * state.carouselCategories.length)]
	},
	getMarketingInfo: (state) => {
		return state.marketingInfo
	},
	lastSearch: (state) => {
		return state.lastSearch
	},
	availableStores: (state) => {
		if (state.availableStores) {
			return state.availableStores.map(String)
		}
	},
	cep: (state) => {
		return state.cep
	},

	cepBloqueado: (state) => {
		return state.cepBloqueado
	},

	cidadeSelecionada: (state) => {
		return state.cidadeSelecionada
	},

	concessionariaSelecionada: (state) => {
		return state.concessionariaSelecionada
	},

	marketingJson: (state) => {
		return state.marketingJson
	},

	configJson: (state) => {
		return state.configJson
	},

	featuresJson: (state) => {
		return state.featuresJson
	},

	localesJson: (state) => {
		return state.localesJson
	},

	mostrarPreco: (state, getters) => {
		const featuresJson = getters["featuresJson"]
		const selecionouCidade = getters["selecionouCidade"]

		let ret = false

		if (featuresJson.PRICE_SHOW && (!featuresJson.PRICE_REQUIRES_GEOLOCATION || (featuresJson.PRICE_REQUIRES_GEOLOCATION && selecionouCidade))) {
			ret = true
		}

		return ret
	},

	paginasEstaticas: (state) => {
		return state.paginasEstaticas
	},

	selecionouCidade: (state) => {
		return state.cidadeSelecionada !== null
	},

	selecionouConcessionaria: (state) => {
		return !lodash.isEmpty(state.concessionariaSelecionada)
	},

	selecionouVeiculo: (state) => {
		return !lodash.isEmpty(state.veiculoSelecionado)
	},

	veiculoSelecionado: (state) => {
		return state.veiculoSelecionado
	},

	veiculoSelecionadoNome: (state) => {
		const veiculo = state.veiculoSelecionado

		let ret = ""

		if (!lodash.isEmpty(veiculo)) {
			let vehicleData = Object.values(veiculo)
			let year = vehicleData[0]
			let vehicle = vehicleData[1]
			let modelo = vehicleData[2]

			vehicleData[0] = vehicle
			vehicleData[1] = modelo
			vehicleData[2] = year

			ret = String(Object.values(vehicleData)).replace(/,/g, " - ")
		}
		return ret
	},

	veiculoSelecionadoStringId: (state) => {
		const veiculo = state.veiculoSelecionado

		let ret = ""

		if (!lodash.isEmpty(veiculo)) {
			ret = `${veiculo.ano}_${veiculo.veiculo.toLowerCase()}_${veiculo.modelo.toLowerCase().replace(/ /g, "_")}`
		}

		return ret
	},
	usuario(state) {
		return state.usuario
	},
	autenticado(state) {
		return state.autenticado
	},
	enderecos(state) {
		return state.enderecos
	},
}

export const actions = {
	async nuxtServerInit({ commit, dispatch, getters, state, context }, { req }) {
		// await dispatch("carregarListaPaginasEstaticas")
	},

	async setFeaturedCarousel(context, str) {
		const carouselFeaturedPath = str
		try {
			const data = await this.$axios.get(process.env.baseUrl + carouselFeaturedPath)
			const featuredCarouselIds = data.data.ids
			context.commit("SET_FEATURED_CAROUSEL", featuredCarouselIds)
		} catch (error) {
			console.log(error)
		}
	},
	async setProductByCategory(context, str) {
		const carouselCategoryPath = str
		try {
			const data = await this.$axios.get(process.env.baseUrl + carouselCategoryPath)
			const categories = data.data.categorias
			context.commit("SET_CATEGORIES_CAROUSEL", categories)
		} catch (error) {
			console.log(error)
		}
	},

	setLastSearch: (context, str) => {
		context.commit("SET_LAST_SEARCH", str)
	},

	async comprar(context, obj) {
		const featuresJson = obj.featuresJson
		const route = obj.route
		const path = route.path
		const precoEstoque = obj.precoEstoque
		const precoFinal = precoEstoque
		const produto = obj.produto
		const vm = obj.vm
		const redirecionar = !obj.adicionarAoCarrinho
		const idLoja = obj.idLoja

		const selecionouCidade = vm.$store.getters["selecionouCidade"]
		const selecionouConcessionaria = vm.$store.getters["selecionouConcessionaria"]

		const produtoCarrinho = {
			codigosoriginais: produto.codigosoriginais,
			id: produto.id_produto,
			id_produto_variacao: produto.id_produto_variacao,
			valor: precoFinal,
			id_loja: idLoja,
		}

		let productDetails = obj.productDetails

		let productPrice = productDetails.preco_desconto
		let itemDetails = {
			item_id: obj.produto.id_produto_variacao,
			item_name: obj.produto.descricao,
			coupon: "",
			discount: 0,
			item_brand: "",
			item_category: "",
			item_variant: "",
			price: productPrice,
			currency: "BRL",
			quantity: 1,
		}

		if (process.env.TOKEN_EPA) {
			let $epa = new window._epa({
				cKey: process.env.TOKEN_EPA,
			})

			$epa.send("add_to_cart", {
				currency: "BRL",
				value: productPrice,
				items: [itemDetails],
				pagePath: vm.$route.fullPath,
			})
		}

		vm.$gtmHelpers.sendEvent.withProducts({
			event: "addToCart",
			products: {
				lists: {
					listaProdutos: [produto],
					listaPrecoEstoque: [precoEstoque],
				},
			},
			pagePath: vm.$route.fullPath,
		})

		if (featuresJson.SELLER && !featuresJson.PRICE_REQUIRES_GEOLOCATION) {
			await context.dispatch("Carrinho/adicionarProduto", produtoCarrinho)

			if (redirecionar) {
				vm.$nuxt.$router.push("/checkout/carrinho")
			}
		}
		//SDLG && VW
		else if (featuresJson.SELLER && featuresJson.PRICE_REQUIRES_GEOLOCATION) {
			if (!selecionouCidade) {
				vm.$eventbus.$emit("ComponentMinhaLocalizacao/abrir", {
					callback: async () => {
						await context.dispatch("Carrinho/adicionarProduto", produtoCarrinho)

						if (redirecionar) {
							vm.$nuxt.$router.push("/checkout/carrinho")
						}
					},
				})
			} else {
				await context.dispatch("Carrinho/adicionarProduto", produtoCarrinho)

				if (redirecionar) {
					vm.$nuxt.$router.push("/checkout/carrinho")
				}
			}
		}
		//H-D Brasil
		else if (!featuresJson.SELLER && featuresJson.GEOLOCATION_SELECT) {
			const destinoRedirect = await context.dispatch("getProductUrl", {
				produto,
				vm,
			})

			let selecioneSuaLojaPayload = {
				cep: null,
				cidade: null,
				destinoRedirect,
			}

			if (!selecionouCidade) {
				vm.$eventbus.$emit("ComponentMinhaLocalizacao/abrir", {
					callback: async () => {
						selecioneSuaLojaPayload.cep = localStorage.getItem("epeca-hub-b2c/cep")
						selecioneSuaLojaPayload.cidade = localStorage.getItem("epeca-hub-b2c/cidade")

						console.log("old-rule hd")
					},
				})
			} else {
				if (selecionouConcessionaria) {
					console.log("old rule hd")
				} else {
					selecioneSuaLojaPayload.cep = localStorage.getItem("epeca-hub-b2c/cep")
					selecioneSuaLojaPayload.cidade = localStorage.getItem("epeca-hub-b2c/cidade")

					console.log("old-rule hd")
				}
			}
		}
	},

	definirCep: (context, str) => {
		if (str) {
			localStorage.setItem("epeca-hub-b2c/cep", str)
			context.commit("DEFINIR_CEP", str)
		} else {
			localStorage.removeItem("epeca-hub-b2c/cep")
			context.commit("DEFINIR_CEP", null)
		}
	},

	definirCepBloqueado: (context, bool) => {
		context.commit("DEFINIR_CEP_BLOQUEADO", bool)
	},

	definirCidadeSelecionada(context, str) {
		if (str) {
			// localStorage.setItem("epeca-hub-b2c/cidade", str);
			this.$cookies.set("selectedCity", true, {
				path: "/",
			})
			context.commit("DEFINIR_CIDADE_SELECIONADA", str)
		} else {
			// localStorage.removeItem("epeca-hub-b2c/cidade");
			this.$cookies.remove("selectedCity")
			context.commit("DEFINIR_CIDADE_SELECIONADA", null)
		}
	},

	definirConcessionariaSelecionada: (context, obj) => {
		if (obj) {
			localStorage.setItem("epeca-hub-b2c/concessionaria", JSON.stringify(obj))
			context.commit("DEFINIR_CONCESSIONARIA_SELECIONADA", obj)
		} else {
			localStorage.removeItem("epeca-hub-b2c/concessionaria")
			context.commit("DEFINIR_CONCESSIONARIA_SELECIONADA", {})
		}
	},

	definirMarketingJson: (context, obj) => {
		context.commit("DEFINIR_MARKETING_JSON", obj)
	},

	definirBannerJson: (context, obj) => {
		context.commit("DEFINIR_BANNER_JSON", obj)
	},

	definirConfigJson: (context, obj) => {
		context.commit("DEFINIR_CONFIG_JSON", obj)
	},

	definirFeaturesJson: (context, obj) => {
		context.commit("DEFINIR_FEATURES_JSON", obj)
	},

	definirLocalesJson: (context, obj) => {
		context.commit("DEFINIR_LOCALES_JSON", obj)
	},

	definirVeiculoSelecionado: (context, obj) => {
		if (obj) {
			delete obj.produtos

			const stringId = `${obj.ano}_${obj.veiculo.toLowerCase()}_${obj.modelo.toLowerCase().replace(/ /g, "_")}`

			sessionStorage.setItem("epeca-hub-b2c/veiculo-selecionado", JSON.stringify(obj))
			sessionStorage.setItem("epeca-hub-b2c/veiculo-selecionado/string-id", stringId)

			context.commit("DEFINIR_VEICULO_SELECIONADO", obj)
		} else {
			sessionStorage.removeItem("epeca-hub-b2c/veiculo-selecionado")
			sessionStorage.removeItem("epeca-hub-b2c/veiculo-selecionado/string-id")

			context.commit("DEFINIR_VEICULO_SELECIONADO", {})
		}
	},
	logout(context) {
		localStorage.removeItem("epeca-hub-b2c/token")
		localStorage.removeItem("epeca-hub-b2c/usuario/email")
		localStorage.removeItem("epeca-hub-b2c/usuario/identificador")
		localStorage.removeItem("epeca-hub-b2c/usuario/nome")
		this.$cookies.remove("epeca-hub-b2c/token")

		context.dispatch("definirAutenticado", false)
	},

	definirAutenticado(context, valor) {
		context.commit("DEFINIR_AUTENTICADO", valor)
	},

	definirUsuario(context, obj) {
		context.commit("DEFINIR_USUARIO", obj)
	},

	carregarUsuario(context) {
		return new Promise((resolve, reject) => {
			let lsToken = localStorage.getItem("epeca-hub-b2c/token")
			let lsUsuarioIdentificador = localStorage.getItem("epeca-hub-b2c/usuario/identificador")

			const usuarioIdentificador = lsUsuarioIdentificador ? lsUsuarioIdentificador.replace(/-/g, "").replace(/\./g, "") : null
			const token = lsToken

			if (token !== null && usuarioIdentificador !== null) {
				this.$axios
					.get(API_B2C_BACKEND + `/cliente/${usuarioIdentificador}?token=${TOKEN_EPA}`)
					.then((response) => {
						let data = response.data.data
						if (data.ERROR) {
							context.dispatch("logout")

							context.dispatch(
								"ModalAlert/adicionarAlert",
								{
									idAlert: this._vm.$helpers.uuidv4(),
									titulo: "Login",
									mensagem: "Falha ao autenticar. Verifique seu nome de usuário e senha e tente novamente.",
								},
								{
									root: true,
								}
							)

							reject(data.message)
						} else {
							context.dispatch("definirAutenticado", true)

							context.dispatch("definirUsuario", data)

							context.dispatch("definirEnderecos", data)

							resolve(data)
						}
					})
					.catch((error) => {
						context.dispatch("logout")

						reject(error)
					})
			} else {
				reject()
			}
		})
	},

	definirEnderecos(context, usuario) {
		let enderecos = [usuario.end_principal, ...usuario.end_secundarios]

		context.commit("DEFINIR_ENDERECOS", enderecos)
	},

	carregarEnderecos(context) {
		return new Promise((resolve, reject) => {
			const lsToken = localStorage.getItem("epeca-hub-b2c/token")
			const lsEmail = localStorage.getItem("epeca-hub-b2c/usuario/email")
			const identificador = context.getters["usuario"].identificador

			if (lsToken !== null && lsEmail !== null) {
				this.$axios
					.get(API_B2C_BACKEND + `/cliente/${identificador}?token=${TOKEN_EPA}`)
					.then((response) => {
						const usuario = response.data.data

						context.dispatch("definirEnderecos", usuario)

						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
			}
		})
	},

	async loadMarketingInfo(context) {
		return await this.$axios
			.get(this.$epecaHubB2c.configJson.FILES_MARKETING_INFO)
			.then((res) => {
				context.commit("SET_MARKETINGINFO", res.data)
			})
			.catch((error) => {})
	},

	carregarListaPaginasEstaticas(context) {
		return this.$axios
			.get(API_B2C_BACKEND + `/pagina_estatica?token=${TOKEN_EPA}`)
			.then((response) => {
				const data = response.data.data

				context.commit("DEFINIR_PAGINAS_ESTATICAS_LISTA", data)
			})
			.catch((error) => {})
	},

	carregarConteudoPaginaEstatica(context, parametros) {
		return new Promise((resolve, reject) => {
			let urlComecaComBarra = parametros.parametro.substr(0, 1) == "/"
			if (urlComecaComBarra) parametros.parametro = parametros.parametro.substr(1)

			this.$axios
				.get(API_B2C_BACKEND + `/pagina_estatica/${parametros.parametro}?token=${TOKEN_EPA}`)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	carregarPedidos(context) {
		return new Promise((resolve, reject) => {
			let lsToken = localStorage.getItem("epeca-hub-b2c/token")

			let identificador = context.getters["usuario"].identificador
			if (!identificador) {
				identificador = localStorage.getItem("epeca-hub-b2c/usuario/identificador")
			}

			if (lsToken === null) {
				reject("Token inválido")
				return false
			}

			if (identificador === false) {
				reject("Identificador do usuário inválido")
				return false
			}

			this.$axios
				.get(API_B2C_BACKEND + `/cliente/${identificador}/pedidos?token=${TOKEN_EPA}`)
				.then((response) => {
					resolve(response)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	setStoreIds(context, arr) {
		if (arr.length > 0) {
			localStorage.setItem("epeca-hub-b2c/availableStores", arr)
			this.$cookies.set("availableStores", arr, {
				path: "/",
			})
			context.commit("SET_STOREIDS", arr)
		} else {
			localStorage.removeItem("epeca-hub-b2c/availableStores")

			this.$cookies.remove("availableStores")

			context.commit("SET_STOREIDS", arr)
		}
	},
}

export const mutations = {
	SET_FEATURED_CAROUSEL: (state, str) => {
		state.carouselFeaturedIds = str
	},
	SET_CATEGORIES_CAROUSEL: (state, str) => {
		state.carouselCategories = str
	},
	SET_LAST_SEARCH: (state, str) => {
		state.lastSearch = str
	},
	DEFINIR_CEP: (state, str) => {
		state.cep = str
	},

	DEFINIR_CEP_BLOQUEADO: (state, bool) => {
		state.cepBloqueado = bool
	},

	DEFINIR_CIDADE_SELECIONADA: (state, str) => {
		state.cidadeSelecionada = str
	},

	DEFINIR_CONCESSIONARIA_SELECIONADA: (state, obj) => {
		state.concessionariaSelecionada = obj
	},

	DEFINIR_MARKETING_JSON: (state, obj) => {
		state.marketingJson = obj
	},

	DEFINIR_CONFIG_JSON: (state, obj) => {
		state.configJson = obj
	},

	DEFINIR_BANNER_JSON: (state, obj) => {
		state.bannerJson = obj
	},

	DEFINIR_FEATURES_JSON: (state, obj) => {
		state.featuresJson = obj
	},

	DEFINIR_LOCALES_JSON: (state, obj) => {
		state.localesJson = obj
	},

	DEFINIR_VEICULO_SELECIONADO: (state, obj) => {
		state.veiculoSelecionado = obj
	},
	DEFINIR_AUTENTICADO(state, valor) {
		state.autenticado = valor
	},

	DEFINIR_USUARIO(state, obj) {
		localStorage.setItem("epeca-hub-b2c/usuario/email", obj.email)
		localStorage.setItem("epeca-hub-b2c/usuario/nome", obj.nome_razao)

		state.usuario = obj
	},

	DEFINIR_ENDERECOS(state, arr) {
		state.enderecos = arr
	},

	DEFINIR_PAGINAS_ESTATICAS_LISTA(state, arr) {
		state.paginasEstaticas.lista = arr
	},
	SET_STOREIDS(state, arr) {
		state.availableStores = arr
	},
	SET_MARKETINGINFO(state, arr) {
		state.marketingInfo = arr
	},
}
